import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { Phone } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faBrain} from '@fortawesome/free-brands-svg-icons';
import Healthcareai from '../images/Healthcare-ai.jpg';
import Healthcareai2 from '../images/Healthcare-ai2.jpg';
import Community from '../images/Community.webp';
import Puzzle from '../images/Puzzle.png';
import Addidas from '../images/Addidas.png'
import Nike from '../images/Nike.png'
import Microsoft from '../images/Microsoft.jpeg'
import Ibm from '../images/Ibm.png'
import Google from '../images/Google.png'
import Ngo from '../images/Ngo.png'
import Nestle from '../images/Nestle.png'
import Hp from '../images/Hp.png'
import Mentalhealth from '../images/Mentalhealth1234.png'
import Home1 from '../images/home1.png'
import Home2 from '../images/Home2.png'
import AIhome from '../images/AIPic.webp'
import Landing1 from '../images/Landing1.svg'
import Landinghand from '../images/Group 88.svg'
import Create1 from '../images/Create1.svg'
import Create2 from '../images/Create2.svg'
import Create3 from '../images/Create3.svg'
import Create4 from '../images/Create4.svg'
import Mood1 from '../images/bro.svg'
import Mood2 from '../images/Character.svg'
import Mood3 from '../images/Frame 182.svg'
import Mood4 from '../images/Frame 207.svg'
import Mood5 from '../images/Game.svg'
import Blog1 from '../images/Blog1.svg'
import Blog2 from '../images/Blog2.svg'
import Blog3 from '../images/Blog3.svg'
import Journal1 from '../images/Journal1.svg'
import MaskGirl from '../images/MaskGroup.svg'
import SafeSpace from '../images/SafeSpace.svg'
import SafeSpace1 from '../images/Rectangle.svg'
import SafeSpace2 from '../images/Rectangle2.svg'
import SafeSpace3 from '../images/Rectangle (2).svg'
import SafeSpace4 from '../images/Rectangle (3).svg'
import Afo from '../images/Frame 209.svg'
import Connection from '../images/Connection.svg'
import ChatbotButton from './ChatbotButton';

const Home = () => { 
  const [currentSlide, setCurrentSlide] = useState(0);
  const [issues, setIssues] = useState(['Mind']);
  const [currentIssueIndex, setCurrentIssueIndex] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [images, setImages] = useState([ Mentalhealth]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sectionRef = useRef(null);
  const thirdSectionRef = useRef(null);
  const [openIndex, setOpenIndex] = useState(6);
  const [hearts, setHearts] = useState([]);
  const FloatingHeart = ({ style }) => (
    <div 
      className="absolute animate-float"
      style={style}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" className="text-[#86efac] fill-current">
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
      </svg>
    </div>
  );

   useEffect(() => {
    const generateHearts = () => {
      const newHearts = Array.from({ length: 15 }, (_, i) => ({
        id: i,
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 5}s`,
        opacity: 0.3 + Math.random() * 0.7,
        scale: 0.5 + Math.random() * 1,
        animationDuration: `${8 + Math.random() * 7}s`
      }));
      setHearts(newHearts);
    };

    generateHearts();
    const interval = setInterval(generateHearts, 10000);
    return () => clearInterval(interval);
  }, []);

  const steps = [
    {
      number: 1,
      title: "Create an Account",
      description: "Log in to your existing SereniMind account or create a new one if you have not registered yet.",
      imagePosition: "right",
      image: Create1
    },
    {
      number: 2,
      title: "Click Book Professional",
      description: "Once logged in, go to the \"Book Professional\" section located in the \"Features\" dashboard.",
      imagePosition: "left",
      image: Create2
    },
    {
      number: 3,
      title: "Choose an Appointment type",
      description: "Browse through our list of available appointment type.",
      imagePosition: "right",
      image: Create3
    },
    {
      number: 4,
      title: "Pick a Time That Works for You",
      description: "Select your preferred day and time that works for you and confirm your appointment, based on your convenience.",
      imagePosition: "left",
      image: Create4
    }
  ];

  const Step = ({ number, title, description }) => (
    <div className="flex items-start mb-4">
      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-green-700 text-white flex items-center justify-center mr-3">
        {number}
      </div>
      <div>
        <h3 className="font-semibold text-lg">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );

  const TestimonialCard = ({ name, rating, text }) => (
    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
      <div className="flex items-center mb-2">
        {[...Array(5)].map((_, i) => (
          <svg
            key={i}
            className={`w-5 h-5 ${i < rating ? 'text-[#59844F]' : 'text-gray-300'}`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        ))}
      </div>
      <p className="text-gray-600 mb-4">{text}</p>
      <p className="font-semibold text-gray-800">{name}</p>
    </div>
  );

  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const features = [
    {
      icon: "🎨",
      title: "Personalize your journal",
      description: "by choosing colors that reflect your mood and style."
    },
    {
      icon: "🔒",
      title: "Secure and private,",
      description: "only you can access your thoughts."
    },
    {
      icon: "📱",
      title: "A clean, intuitive interface",
      description: "for writing and organizing your entries."
    },
    {
      icon: "💫",
      title: "Optional prompts",
      description: "to guide your journaling practice."
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIssueIndex((prevIndex) => (prevIndex + 1) % issues.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [issues]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images]);

  const formMessage = useRef();
  const formNewsletter = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmittingMessage(true);
    const formData = new FormData(formMessage.current);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/send-email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      if (response.ok) {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          window.location.reload();
        }, 3000);
      } else {
        alert('Failed to send email. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send email. Please try again later.');
    } finally {
      setIsSubmittingMessage(false);
    }
  };

  const subscribeNewsletter = async (e) => {
    e.preventDefault();
    setIsSubscribing(true);
    const formData = new FormData(formNewsletter.current);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/subscribe-newsletter/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        window.location.reload();
      } else {
        alert('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to subscribe. Please try again later.');
    } finally {
      setIsSubscribing(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      },
      {
        threshold: 0.2
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible-pop-down');
          observer.unobserve(entry.target); // Optional: Stop observing after it becomes visible
        }
      },
      {
        threshold: 0.1
      }
    );

    if (thirdSectionRef.current) {
      observer.observe(thirdSectionRef.current);
    }

    return () => {
      if (thirdSectionRef.current) {
        observer.unobserve(thirdSectionRef.current);
      }
    };
  }, []);

  const safespace = [
    {
      title: "Complete Anonymity",
      icon: "🎭", // Theater masks icon
    },
    {
      title: "Supportive Environment",
      icon: "🤝", // Handshake icon
    },
    {
      title: "Topics That Matter",
      icon: "💭", // Speech bubble icon
    },
    {
      title: "Moderated for Safety",
      icon: "🛡️", // Shield icon
    }
  ];

  const [currentSet, setCurrentSet] = useState(0);
  const testimonials = [
    {
      name: "Iremide Onokpise",
      image: MaskGirl,
      testimonial: "These blogs gave me the clarity I needed to manage my stress. Highly recommend!",
      bgColor: "bg-[#fff1f0] border-2 border-[#FFA07A] ",
    },
    {
      name: "Ahmed Al-Mansoori",
      image: MaskGirl,
      testimonial: "Booking a therapist was seamless, and the session helped me more than I could have imagined. Highly recommended!",
      bgColor: "bg-[#eef6ff] border-2 border-[#32ADE6]",
    },
    {
      name: "Afolabi Adekunle",
      image: MaskGirl,
      testimonial: "The daily prompts in the journal keep me grounded. SereniMind is like a friend I can turn to anytime.",
      bgColor: "bg-[#f0fff4] border-2 border-[#92E3A9]",
    },
    
    {
      name: "Jane Ajayi",
      image: MaskGirl,
      testimonial: "I love how relatable and easy-to-read the blogs are. They're my go-to for daily inspiration.",
      bgColor: "bg-[#eef6ff] border-2 border-[#32ADE6]",
    },
    {
      name: "Olivia Thompson",
      image: MaskGirl,
      testimonial: "The anonymous community gave me a safe space to share my thoughts without judgment. It's comforting to know I'm not alone.",
      bgColor: "bg-[#f0fff4] border-2 border-[#92E3A9]",
    }
  ];
  const testimonials2 = [
    {
      name: "Lisa Edwards",
      image: MaskGirl,
      testimonial: "The platform feels so secure and private. It's a relief to have a trusted space for my mental health journey.",
      bgColor: "bg-[#fff1f0] border-2 border-[#FFA07A] ",
    },
    {
      name: "James Carter",
      image: MaskGirl,
      testimonial: "Serenimind has truly transformed how I manage stress. The resources are invaluable!",
      bgColor: "bg-[#f0fff4] border-2 border-[#32CD32] ",
    },
    {
      name: "Amara Johnson",
      image: MaskGirl,
      testimonial: "I love the supportive community. It's a safe space where I feel heard and understood.",
      bgColor: "bg-[#f0f8ff] border-2 border-[#4682B4] ",
    },
    {
      name: "David Olamide",
      image: MaskGirl,
      testimonial: "The AI chatbot is surprisingly insightful. It helps me reflect on my emotions in ways I never expected.",
      bgColor: "bg-[#fffaf0] border-2 border-[#FFD700] ",
    },
    {
      name: "Sophia Martins",
      image: MaskGirl,
      testimonial: "Journaling on Serenimind feels therapeutic. Knowing my entries are encrypted gives me peace of mind.",
      bgColor: "bg-[#f5f5f5] border-2 border-[#A9A9A9] ",
    },
    {
      name: "Emmanuel Wright",
      image: MaskGirl,
      testimonial: "Finally, a mental health platform that understands me! Serenimind is a game-changer.",
      bgColor: "bg-[#fdfd96] border-2 border-[#FFD700] ",
    },    
  ];

  // Double the testimonials array for seamless loop
  const doubledTestimonials = [...testimonials, ...testimonials];
  
  const doubledTestimonial = [...testimonials2, ...testimonials2];

  const [isMobile, setIsMobile] = useState(false);

  // Check screen size and set mobile view
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check on initial load
    checkMobileView();

    // Add resize listener
    window.addEventListener('resize', checkMobileView);

    // Cleanup listener
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  const [isHovered, setIsHovered] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  const handleAIClick = () => {
    setShowFAQ(true);
  };
  
  const handleCloseAI = () => {
    setShowFAQ(false);
  };
  
  const faqs = [
    {
      question: "What services do you offer?",
      answer: "We offer a range of healthcare and wellness services including consultations, check-ups, and specialized care."
    },
    {
      question: "How can I book an appointment?",
      answer: "You can book an appointment through our website, mobile app, or by calling our customer service."
    },
    {
      question: "What are your operating hours?",
      answer: "We are open Monday through Friday from 8AM to 6PM, and Saturdays from 9AM to 2PM."
    }
  ];

  return (
    <div  className='mt-[95px]'>
      <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Poppins:wght@400;500;600&display=swap" rel="stylesheet" />
      <link href="https://api.fontshare.com/v2/css?f[]=lostamasta@400&display=swap" rel="stylesheet" />

      {/* First Page in Home */}

      <section className="relative min-h-screen bg-[#ffffff] overflow-hidden">
      {/* Floating Hearts Background */}
      {hearts.map((heart) => (
        <FloatingHeart
          key={heart.id}
          style={{
            left: heart.left,
            top: heart.top,
            animationDelay: heart.animationDelay,
            opacity: heart.opacity,
            transform: `scale(${heart.scale})`,
            '--animation-duration': heart.animationDuration,
          }}
        />
      ))}

      <div className={`container mx-auto ${isMobile ? 'px-4' : 'px-4'} pb-16 pt-4 relative z-10`}>
        <div className={`${isMobile ? 'max-w-full' : 'max-w-3xl'} mx-auto text-center`}>
          <div className={`space-y-${isMobile ? '[-10px]' : '[-20px]'}`}>
            <h1 className={`font-['Lostamasta'] text-[#2d4f3f] ${isMobile ? 'text-[26px]' : 'text-[64px]'} font-semibold`}>
              Start Your Journey 
            </h1>
            <h1 className={`font-['Lostamasta'] text-[#2d4f3f] ${isMobile ? 'text-[26px]' : 'text-[64px]'} font-semibold ${isMobile ? 'mt--6' : 'mt--6'}`}>
              To a Healthier Mind Today
            </h1>
          </div>
          
          <p className={`font-['Merriweather'] text-[#8f8f8f] ${isMobile ? 'text-[12px]' : 'text-md'} mb-8 ${isMobile ? 'max-w-full' : 'max-w-2xl'} mx-auto`}>
            Receive personalized wellness recommendations from our AI chatbot or reach
            out to a qualified therapist in a secure and supportive environment.
          </p>

          <Link to='/signup'>
            <button className="bg-[#ffa07a] text-white font-['Poppins'] px-8 py-3 rounded-full 
                hover:bg-[#ff8c61] transition-colors duration-300">
                Begin Your Journey
            </button>
          </Link>
          
          <div className={`flex ${isMobile ? 'flex-col items-center' : 'justify-end'} mt-8`}>
            <div className={`${isMobile ? 'w-full max-w-md' : 'mt-12'}`}>
              <img 
                src={Landing1}
                alt="Meditation illustration"
                className={`mx-auto ${isMobile ? 'w-full' : 'max-w-md'}`}
              />
            </div>

            <div className={`mt-6 flex items-center ${isMobile ? 'justify-center' : 'justify-center'} gap-2 font-['Poppins'] text-sm text-gray-600`}>
              <svg 
                className="w-5 h-5 text-green-500" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" 
                />
              </svg>
              <span>100% secure and confidential</span>
            </div>
          </div>
        </div>
      </div>
    </section>

      {/* Second Page in Home */}
      <section className="bg-gradient-to-t from-white via-[#E0F9EB] to-white min-h-screen relative overflow-hidden">
      <div className="container mx-auto px-6 lg:px-2 pb-4 pt-4">
        <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2'} gap-8 items-center`}>
          {/* Left Content */}
          <div className={`relative ${isMobile ? 'mt-0' : 'mt-[-100px]'}`}>
            <div className="mb-12">
              <h1 className={`font-['Lostamasta'] ${isMobile ? 'text-[28px]' : 'text-[40px]'} mb-6 bg-gradient-to-r from-[#31452C] to-[#81B29A] text-transparent bg-clip-text font-bold`}>
                AI Support Tailored<br />
                Just for <span className="text-[#ffa07a]">You</span>
              </h1>

              <p className={`font-['Merriweather'] text-[#6B6B6B] ${isMobile ? 'text-base' : 'text-lg'} ${isMobile ? 'max-w-full' : 'max-w-[460px]'} mb-12`}>
                Take the first step towards a healthier mind with instant AI assistance designed 
                to cater to your unique needs.
              </p>


              {/* Feature Pills - Staggered Layout */}
              <div className={`space-y-4 relative ${isMobile ? 'flex flex-col items-center' : ''}`}>
                <div className="w-fit">
                  <span className="bg-[#31452C] font-semibold text-white py-2.5 px-6 rounded-full font-['Poppins'] md:text-sm text-[12px]  inline-block">
                    Personalized Guidance
                  </span>
                </div>
                
                <div className={`w-fit ${isMobile ? '' : 'ml-[170px]'}`}>
                  <span className="bg-[#31452C] font-semibold text-white py-2.5 px-6 rounded-full font-['Poppins'] md:text-sm text-[12px] inline-block">
                    Available 24/7
                  </span>
                </div>
                
                <div className={`w-fit ${isMobile ? '' : 'ml-[100px]'}`}>
                  <span className="bg-[#31452C] font-semibold text-white py-2.5 px-6 rounded-full font-['Poppins'] md:text-sm text-[12px] inline-block">
                    Secure & 100% Confidential
                  </span>
                </div>
                
                <div className="w-fit">
                  <span className="bg-[#31452C] font-semibold text-white py-2.5 px-6 rounded-full font-['Poppins'] md:text-sm text-[12px] inline-block">
                    Expert-Backed Recommendations
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Content - Phone Image */}
          <div className={`relative ${isMobile ? 'mt-8' : 'lg:h-full'} flex items-center justify-center ${isMobile ? '' : 'lg:justify-end'}`}>
            {/* Decorative Lines - Hidden on mobile for cleaner look */}
            {!isMobile && (
              <>
                <div className="absolute right-0 top-1/4 -z-10">
                  <svg width="40" height="80" viewBox="0 0 40 80" fill="none" className="text-[#2d4f3f] opacity-30">
                    <path d="M4 4C4 4 36 30 36 40S4 76 4 76" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </div>
                <div className="absolute right-8 bottom-1/4 -z-10">
                  <svg width="40" height="80" viewBox="0 0 40 80" fill="none" className="text-[#2d4f3f] opacity-30">
                    <path d="M4 4C4 4 36 30 36 40S4 76 4 76" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </div>
              </>
            )}

            {/* Phone Image */}
            <div className={`${isMobile ? 'max-w-[300px]' : 'max-w-[400px]'} w-full ${isMobile ? 'mr-0 mt-[-100px]' : 'mr-[-90px] mt-[0px] '}`}>
              <img 
                src={Landinghand}
                alt="AI Support Mobile Interface"
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </div>
        <div className={`flex justify-center ${isMobile ? 'mt-8' : 'mt-[-70px]'}`}>
          <Link to='/sereniai'>
            <button className="bg-[#ffa07a] text-white font-['Poppins'] px-8 py-3 rounded-full 
                    hover:bg-[#ff8c61] transition-colors duration-300 text-sm">
              Start Your Conversation
            </button>
          </Link>
        </div>
      </div>
    </section>
      
      {/* Third page in Home */}
      <div className="max-w-6xl mx-auto px-4 pb-16 pt-7">
      <div className="text-center">
        <h2 className={`${isMobile ? 'text-2xl' : 'text-4xl'} font-semibold mb-2 font-['Lostamasta']`}>
          Booking a Therapist Has Never Been Easier
        </h2>
        <p className={`text-gray-600 ${isMobile ? 'w-full' : 'w-[550px]'} mx-auto`}>
          Our process is designed to be simple and stress free, so you can focus on what matters, your mental health.
        </p>
      </div>

      <div className="mt-12">
        {steps.map((step, index) => (
          <div key={step.number} className={`relative md:mb-16 ${isMobile ? 'text-center' : ''}`}>
            {/* Connecting lines - Hidden on mobile */}
            {!isMobile && step.number < 4 && (
              <div className="absolute left-1/2 h-[350px] border-l-2 border-dashed border-green-200 -bottom-[120px]"></div>
            )}

            <div className={`
              ${isMobile ? 'flex-col' : 'flex'} 
              items-center 
              ${isMobile ? 'space-y-6' : 'gap-8'}
              ${step.imagePosition === "right" && !isMobile ? "flex-row" : (!isMobile ? "flex-row-reverse" : "")}
            `}>
              <div className={`${isMobile ? 'w-full' : 'flex-1'}`}>
                <div className={`flex ${isMobile ? 'flex-col items-center' : 'items-center'} gap-3 mb-9`}>
                  <span className="text-green-500 font-medium text-3xl">#{step.number}</span>
                  <h3 className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-semibold`}>{step.title}</h3>
                </div>
                <p className={`text-[#1A1A1A] ${isMobile ? 'text-base text-center' : 'mx-[70px] mt-8 text-xl'}`}>
                  {step.description}
                </p>
              </div>
              
              <div className={`${isMobile ? 'w-full' : 'flex-1'}`}>
                <div className="rounded-lg p-8 flex items-center justify-center">
                  <img 
                    src={step.image} 
                    alt={`Step ${step.number}: ${step.title}`}
                    className={`${isMobile ? 'max-w-full' : 'max-h-full w-[550px]'} object-contain`}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center">
        <p className="text-gray-600 mb-6">Start your journey today! Log in to book your appointment in minutes.</p>
        <Link to='/book-professional'>
          <button className="bg-[#FFA07A] text-white px-8 py-3 rounded-full hover:bg-coral-600 transition-colors">
            Book Your Session Now
          </button>
        </Link>
      </div>
    </div> 

    {/*The Fourth New home*/}
    <section className="max-w-6xl mx-auto px-4 pb-16 md:pt-7">
      <div className="max-w-7xl mx-auto">
        {/* Header Area */}
        <div className={`${isMobile ? 'flex flex-col' : 'grid grid-cols-12'} gap-6 mb-8`}>
          {/* Text Content */}
          <div className={`${isMobile ? 'w-full' : 'col-span-6'}`}>
            <h2 className={`font-['Lostamasta'] ${isMobile ? 'text-3xl' : 'text-[42px]'}  text-[#31452C] mb-6`}>
              Take a Break with<br />
              Activities Tailored Just<br />
              for you
            </h2>
            <p className={`font-['Merriweather'] text-[#6B6B6B] ${isMobile ? 'text-base w-full' : 'text-md w-80'} mb-8`}>
              Explore handpicked activities to boost your mental well-being and bring balance to your life.
            </p>
          </div>
          {/* Empty Box - Hidden on mobile */}
          {!isMobile && (
            <div className="col-span-6">
              <div className="bg-[#f5f5f5] rounded-b-3xl h-20 w-64 ml-[-90px] pt-[20px]"></div>
            </div>
          )}
        </div>

        {/* Activities Grid */}
        <div className={`${isMobile ? 'flex flex-col space-y-6' : 'grid grid-cols-12 gap-6 mt-[-60px]'}`}>
          {/* Left Column */}
          <div className={`${isMobile ? 'w-full' : 'col-span-4'} space-y-6`}>
            {/* Books Card */}
            <div className="bg-[#fff1f1] rounded-3xl p-8">
              <img src={Mood3} alt="Reading Activity" className="w-full max-w-[300px] object-contain mb-6 mx-auto" />
              <h3 className="font-['Poppins'] font-semibold text-xl mb-3 text-[#2d4f3f] text-center">Books</h3>
              <p className="font-['Merriweather'] text-sm text-gray-700 text-center">
                Discover insightful reads to broaden your perspective and ease your mind.
              </p>
            </div>

            {/* CTA Section */}
            <div className="pt-4 text-center hidden md:block">
              <p className="font-['Merriweather'] text-gray-700 mb-4">
                Not sure where to start? Let us recommend something just for you!
              </p>
              <Link to='/activity-lounge'>
              <button className="bg-[#ffa07a] text-white font-['Poppins'] px-8 py-3 rounded-full 
                hover:bg-[#ff8c61] transition-colors duration-300">
                Get Activity Suggestions
              </button>
              </Link>
              
            </div>
          </div>

          {/* Middle Column */}
          <div className={`${isMobile ? 'w-full' : 'col-span-4'} space-y-6`}>
            {/* Sounds Card */}
            <div className={`bg-[#F6F4F1] rounded-3xl p-8 ${!isMobile ? 'mt-[-160px]' : ''}`}>
              <img src={Mood1} alt="Music Activity" className="w-full max-w-[300px] object-contain mb-6 mx-auto" />
              <h3 className="font-['Poppins'] font-semibold text-xl mb-3 text-[#2d4f3f] text-center">Sounds</h3>
              <p className="font-['Merriweather'] text-sm text-gray-700 text-center">
                Unwind with calming playlists and sounds that bring tranquility.
              </p>
            </div>

            {/* Movies Card */}
            <div className="bg-[#e8f5f0] rounded-t-3xl p-8">
              <img src={Mood4} alt="Movie Activity" className="w-full max-w-[300px] object-contain mb-6 mt-[-32px] mx-auto" />
              <h3 className="font-['Poppins'] font-semibold text-xl mb-3 text-[#2d4f3f] text-center">Movies</h3>
              <p className="font-['Merriweather'] text-sm text-gray-700 text-center">
                Enjoy uplifting films and documentaries tailored for mental clarity and inspiration.
              </p>
            </div>
          </div>

          {/* Right Column */}
          <div className={`${isMobile ? 'w-full' : 'col-span-4'} space-y-6`}>
            {/* Exercises Card */}
            <div className={`bg-[#e8f5f0] rounded-b-3xl p-8 ${!isMobile ? 'mt-[-250px]' : ''}`}>
              <img src={Mood2} alt="Exercise Activity" className="w-full max-w-[300px] object-contain mb-6 mx-auto" />
              <h3 className="font-['Poppins'] font-semibold text-xl mb-3 text-[#2d4f3f] text-center">Exercises</h3>
              <p className="font-['Merriweather'] text-sm text-gray-700 text-center">
                Practice mindfulness with guided activities to relax and recharge.
              </p>
            </div>
            {/* Games Card */}
            <div className="bg-[#fff1f1] rounded-3xl p-8">
              <img src={Mood5} alt="Games Activity" className="w-full max-w-[300px] object-contain mb-6 mt-[-32px] mx-auto" />
              <h3 className="font-['Poppins'] font-semibold text-xl mb-3 text-[#2d4f3f] text-center">Games</h3>
              <p className="font-['Merriweather'] text-sm text-gray-700 text-center">
                Engage in interactive, stress-relieving games to stimulate your mind.
              </p>
            </div>
            {/* Empty Space */}
            
            <div className="h-[30px] bg-[#92E3A999] rounded-t-3xl md:block hidden"></div>
            <div className="pt-4 text-center md:hidden ">
              <p className="font-['Merriweather'] text-gray-700 mb-4">
                Not sure where to start? Let us recommend something just for you!
              </p>
              <button className="bg-[#ffa07a] text-white font-['Poppins'] px-8 py-3 rounded-full 
                hover:bg-[#ff8c61] transition-colors duration-300">
                Get Activity Suggestions
              </button>
            </div>
          </div>
          
        </div>
        
      </div>
    </section>

        {/* Fifth page in home */}
        <section className="bg-gradient-to-t from-[#81B29A26] via-[#92E3A926] to-[#ffffff] overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 pb-4 pt-7">
        <div className={`${isMobile ? 'flex flex-col' : 'grid grid-cols-1 lg:grid-cols-2'} gap-4 items-center`}>
          {/* Left Column - Illustration */}
          <div className="relative">
            <img 
              src={Blog3} 
              alt="Person reading blogs" 
              className="w-full max-w-md mx-auto"
            />
          </div>

          {/* Right Column - Content */}
          <div className="space-y-2">
            <div className="mb-12">
              <h2 className={`font-['Lostamasta'] ${isMobile ? 'text-3xl' : 'text-[48px]'} leading-[29px] md:leading-[50px] text-[#2d4f3f] mb-2`}>
                Discover Quick<br />
                Reads for Wellness
              </h2>
              <p className={`font-['Merriweather'] text-gray-700 ${isMobile ? 'text-base' : 'text-lg'}`}>
                Explore bite-sized blogs for a balanced mind.
              </p>
            </div>

            {/* Blog Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Blog Card 1 */}
              <div className="bg-white rounded-3xl p-6 shadow-sm">
                <img 
                  src={Blog1} 
                  alt="Breaking the Stigma" 
                  className="w-full h-48 object-cover rounded-2xl mb-4"
                />
                <h3 className="font-['Poppins'] font-semibold text-lg mb-2 text-[#2d4f3f]">
                  Breaking the Stigma of Therapy
                </h3>
                <p className="font-['Merriweather'] text-sm text-gray-600 mb-4">
                  Exploring common conversations about seeking professional help...
                </p>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-500">April 17, 2024</span>
                  <button className="text-[#2d4f3f] font-['Poppins'] font-medium text-sm hover:underline">
                    Read More
                  </button>
                </div>
              </div>

              {/* Blog Card 2 */}
              <div className="bg-white rounded-3xl p-6 shadow-sm">
                <img 
                  src={Blog2} 
                  alt="Healthy Habits" 
                  className="w-full h-48 object-cover rounded-2xl mb-4"
                />
                <h3 className="font-['Poppins'] font-semibold text-lg mb-2 text-[#2d4f3f]">
                  Healthy Habits for a Happy Life
                </h3>
                <p className="font-['Merriweather'] text-sm text-gray-600 mb-4">
                  Tips on turning simple healthy habits into your daily routine...
                </p>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-500">April 17, 2024</span>
                  <Link to='/blogs'>
                  <button className="text-[#2d4f3f] font-['Poppins'] font-medium text-sm hover:underline">
                    Read More
                  </button>
                  </Link>
                </div>
              </div>
            </div>

            {/* CTA Button */}
            <div className="flex justify-center mt-8 py-6">
            <Link to='/blogs'>
            <button className="bg-[#ffa07a] text-white font-['Poppins'] px-8 py-3 rounded-full 
                hover:bg-[#ff8c61] transition-colors duration-300">
                Explore Blogs
              </button>
            </Link>
            </div>

            {/* Testimonial */}
            <div className="bg-[#fff1f1] rounded-tr-3xl rounded-b-3xl p-6 mt-8 border-[#FFA07A] border-2">
              <div className={`flex ${isMobile ? 'flex-col items-center text-center space-y-4' : 'items-center gap-4'}`}>
                <img 
                  src={MaskGirl} 
                  alt="Iremide Onokpise" 
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div>
                  <h4 className="font-['Poppins'] font-semibold text-[#2d4f3f]">
                    Iremide Onokpise
                  </h4>
                  <p className="font-['Merriweather'] text-sm text-gray-700">
                    These blogs gave me the clarity I needed to manage my stress. Highly recommend!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

 {/* Sixth page in home */}
    <div className="bg-gradient-to-t from-[#FFA07A26] via-[#FFA07A26] to-[#81B29A26] overflow-hidden min-h-screen flex items-center">
      <div className="max-w-6xl mx-auto px-4 pb-4  flex flex-col md:flex-row items-center gap-12">
        {/* Left Content */}
        <div className="flex-1 space-y-8">
          <div className="space-y-4">
            <h2 className="font-['Lostamasta'] text-[#2d4739] text-4xl md:text-5xl leading-tight">
              Track Your Journey<br />to Wellness
            </h2>
            <p className="font-['Merriweather'] text-gray-600 max-w-md">
              Capture your thoughts and emotions in a safe digital journal.
            </p>
          </div>
          <div className="flex-1 relative md:hidden">
          <div className="relative w-full max-w-md mx-auto">
            <img
              src={Journal1}
              alt="Digital journal with calendar and to-do list"
              className="w-full h-auto"
            />
          </div>
        </div>

          <div className="space-y-6">
            {features.map((feature, index) => (
              <div key={index} className="flex items-start gap-4">
                <span className="text-2xl">{feature.icon}</span>
                <p className="font-['Merriweather'] text-gray-700">
                  <span className="font-semibold">{feature.title}</span>{' '}
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
          <Link to='/journal'>
          <button className="bg-[#ff9f87] text-white px-8 py-3 rounded-full hover:bg-[#ff8a6d] transition-colors font-['Merriweather']">
            Start Journaling Today
          </button>
          </Link>
        </div>

        {/* Right Image */}
        <div className="flex-1 relative md:block hidden">
          <div className="relative w-full max-w-md mx-auto">
            <img
              src={Journal1}
              alt="Digital journal with calendar and to-do list"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>

  {/* Seventh page in home */}
  <div className=" min-h-screen bg-gradient-to-t from-[#ffffff] via-[#FFA07A26] to-[#FFA07A26] overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 pb-4 ">
        <div className="flex flex-col md:flex-row items-start gap-12">
          {/* Left side placeholder - empty space */}
          <div className="flex" />
          <div className='md:block hidden'>
            <img 
            src={SafeSpace}
            alt="Safe space chat interface"
            className="w-[500px] max-w-md mx-auto relative z-10 h-[600px]" />
          </div>

          {/* Right Content Section */}
          <div className="flex-1 space-y-12">
            {/* Header and description */}
            <div className="space-y-6">
              <h2 className="font-['Lostamasta'] text-[#2d4739] md:text-5xl text-4xl leading-tight">
                Your Safe Space to<br />Share
              </h2>
              <p className="font-['Merriweather'] text-gray-600 text-lg max-w-md">
                Join a supportive community where you can express yourself freely and find encouragement without revealing your identity.
              </p>
            </div>
            <div className='md:hidden'>
            <img 
            src={SafeSpace}
            alt="Safe space chat interface"
            className="md:w-[500px] max-w-md mx-auto relative z-10 md:h-[600px]" />
          </div>

            {/* Feature Tags with specific positioning */}
            <div className="relative space-y-4">
              {/* First row */}
              <div className="flex justify-start gap-4 mb-4 space-x-36">
                <div className="flex items-center gap-2 bg-[#e8f3ef] px-6 py-2.5 rounded-b-3xl rounded-tr-3xl border-[#81B29A] border-2">
                 
                  <span className="font-['Merriweather'] text-[#81B29A]">Complete Anonymity</span>
                </div>
                <div>
                <span className="w-5 h-5">
                    <img src={SafeSpace1} />
                  </span>
                </div>
              </div>

              {/* Second row */}
              <div className="flex justify-end gap-4 mb-4 space-x-36">
              <div>
                <span className="w-5 h-5">
                    <img src={SafeSpace2} />
                  </span>
                </div>
                <div className="flex items-center gap-2 bg-[#e8f3ef] px-6 py-2.5 rounded-b-3xl rounded-tl-3xl border-[#81B29A] border-2">
                 
                  <span className="font-['Merriweather'] text-[#81B29A]">Supportive Environment</span>
                </div>
                
              </div>

              {/* Third row */}
              <div className="flex justify-start gap-4 mb-4 space-x-36">
                <div className="flex items-center gap-2 bg-[#e8f3ef] px-6 py-2.5 rounded-b-3xl rounded-tr-3xl border-[#81B29A] border-2">
                  
                  <span className="font-['Merriweather'] text-[#81B29A]">Topics That Matter</span>
                </div>
                <div>
                <span className="w-5 h-5">
                    <img src={SafeSpace3} />
                  </span>
                </div>
              </div>

              {/* Fourth row */}
              <div className="flex justify-end gap-4 space-x-36">
              <div>
                <span className="w-5 h-5">
                    <img src={SafeSpace4} />
                  </span>
                </div>
                <div className="flex items-center gap-2 bg-[#e8f3ef] px-6 py-2.5 rounded-b-3xl rounded-tl-3xl border-[#81B29A] border-2">
                 
                  <span className="font-['Merriweather'] text-[#81B29A]  ">Moderated for Safety</span>
                </div>
                
              </div>
            </div>

            {/* CTA Button */}
            <Link to='/community' className=''>
            <button className="bg-[#ff9f87] text-white px-8 py-3 rounded-full hover:bg-[#ff8a6d] transition-colors font-['Merriweather'] mt-3">
              Find Your Support Network
            </button>
            </Link>
            
          </div>
        </div>
      </div>
    </div>

  {/* Eight page in home */}

  <div className="mx-auto py-16 overflow-hidden">
      {/* Header */}
      <div className="text-center mb-12">
        <h2 className="font-['Lostamasta'] text-[#2d4739] text-4xl md:text-5xl">
          Hear from Our Community
        </h2>
      </div>

      {/* Sliding Testimonials Container */}
      <div className="relative">
        <div className=" space-y-16">
          {/* First set of testimonials */}
          <div className="flex gap-6 min-w-max animate-slide">
            {doubledTestimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`${testimonial.bgColor} rounded-b-3xl rounded-tr-3xl px-6 py-1 w-[350px]`}
              >
                {/* Profile section */}
                <div className="flex items-center gap-3 mb-2">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <h3 className="font-['Poppins'] font-normal text-[#2d4739]">
                    {testimonial.name}
                  </h3>
                </div>

                {/* Testimonial text */}
                <p className="font-['Merriweather'] text-gray-700 leading-relaxed text-[13px]">
                  {testimonial.testimonial}
                </p>
              </div>
            ))}
          </div>
          <div className="flex gap-6 min-w-max animate-slideb">
            {doubledTestimonial.map((testimonial, index) => (
              <div
                key={index}
                className={`${testimonial.bgColor} rounded-b-3xl rounded-tr-3xl px-6 py-1 w-[350px]`}
              >
                {/* Profile section */}
                <div className="flex items-center gap-3 mb-2">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <h3 className="font-['Poppins'] font-normal text-[#2d4739]">
                    {testimonial.name}
                  </h3>
                </div>

                {/* Testimonial text */}
                <p className="font-['Merriweather'] text-gray-700 leading-relaxed text-[13px]">
                  {testimonial.testimonial}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes slide {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .animate-slide {
          animation: slide 30s linear infinite;
        }

          @keyframes slideReverse {
        0% {
          transform: translateX(-50%);
        }
        100% {
          transform: translateX(0);
        }
      }

      .animate-slideb {
        animation: slideReverse 30s linear infinite;
      }


      `
      }</style>
    </div>

        {/* Ninth page in home */}
      <div ref={thirdSectionRef} className="third-section mt-[10px] md:flex justify-center">
        <div>
          <img src={Afo} />
        </div>
        <div className="landing pb-[10px] md:px-12 mx-4">
        <h1 className='md:text-3xl font-bold text-center text-2xl mb-3'>Frequently Asked Questions</h1>
          <p className="text-center text-gray-600 mb-8">Everything you need to know about SereniMind and more</p>
          <div className="max-w-2xl mx-auto w-[400px]">
            {[
              { question: "What is mind wellness?", 
                answer: "Mind wellness focuses on maintaining mental and emotional health through practices that promote cognitive functioning, emotional stability, and overall psychological well-being." },
              { question: "What can the SereniAI help me with?", 
                answer: "The SereniAI can provide immediate support, answer questions related to mental health, offer stress-relief techniques, and guide you through mindfulness exercises." },
              { question: "Is the SereniAI confidential?", 
                answer: "Yes, interactions with the AI chatbot are confidential and designed to protect your privacy." },
              { question: "What is the purpose of the private journal?", 
                answer: "The private journal allows you to document your thoughts, feelings, and experiences, which can help you reflect on your mental wellness journey and track your progress over time." },
              { question: "Are virtual appointments available?", 
                answer: "Yes, we offer virtual appointments to provide you with convenient access to mental health support from the comfort of your home." },
              { question: "How can I join the community forum?", 
                answer: "You can join the community forum by creating an account on our website. Once registered, you can participate in discussions, post questions, and connect with other members." },
              {
                question: "What should I do if I'm in a mental health crisis?",
                answer: "If you are in a mental health crisis, please seek immediate help from a professional. Contact emergency services, visit the nearest hospital, or reach out to a crisis hotline."
              }
            ].map((item, index) => (
              <div key={index} className="border-b border-gray-200 py-4">
                <button
                  className="flex justify-between items-center w-full text-left"
                  onClick={() => setOpenIndex(openIndex === index ? -1 : index)}
                >
                  <span className="text-lg font-medium">{item.question}</span>
                  <span className="text-2xl">{openIndex === index ? '−' : '+'}</span>
                </button>
                {openIndex === index && <p className="mt-2 text-gray-600">{item.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>


      {/* Tenth Page in Home */}
      <div className="max-w-6xl mx-auto px-4 py-4 ">
      <div className="bg-[#E76F51] rounded-3xl px-8 py-16 text-center relative overflow-hidden">
        {/* Main Content */}
        <div className="max-w-2xl mx-auto relative z-10">
          <h2 className="font-['Lostamasta'] text-white text-4xl md:text-5xl mb-4">
            Start Your Mental Wellness Journey Today
          </h2>
          
          <p className="font-['Merriweather'] text-white/90 text-lg mb-8">
            Connect with our AI for personalized wellness insights or talk to a certified therapist, all in 
            a secure space.
          </p>

          {/* CTA Button */}
          <Link to='/login'>
          <button className="bg-[#98f3c3] text-[#2d4739] font-['Merriweather'] font-medium px-8 py-3 rounded-full text-lg hover:bg-[#7de3b1] transition-colors mb-8">
            Begin Your Journey
          </button>
          </Link>
          

          {/* Social Proof Section */}
          <div className="flex flex-col items-center gap-4">
            {/* User Images */}
            <div className="flex items-center justify-center -space-x-3">
              <img 
                src={Connection}
                alt="Community members" 
                className="h-10"
              />
            </div>

            {/* Join Text */}
            <p className="text-white font-['Merriweather'] text-sm">
              Join 5,000+ others improving their mental health
            </p>

            {/* Security Badge */}
            <div className="flex items-center gap-2 text-white/90 font-['Merriweather'] text-sm">
              <svg 
                viewBox="0 0 20 20" 
                className="w-5 h-5 text-[#98f3c3]" 
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              100% secure and confidential
            </div>
          </div>
        </div>

        {/* Background Pattern - Optional decorative circles */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute right-0 top-0 w-64 h-64 rounded-full bg-white transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute left-0 bottom-0 w-64 h-64 rounded-full bg-white transform -translate-x-1/2 translate-y-1/2" />
        </div>
      </div>
    </div>
    <ChatbotButton/>
    
    </div>
  )
}

export default Home;
