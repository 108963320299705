import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faPhone, faEnvelope, faPenSquare, faArrowRight, faLanguage, faGear, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'; 
import 'react-phone-input-2/lib/style.css'; 
import { PaystackButton } from 'react-paystack';

const BookServices = () => {
  const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };  
  
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    reason: '',
    phoneNumber: '',
    email: '',
    language: '',
    plan: '',
    anonymous: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const toggleActivities = () => {
    setIsActivitiesOpen(!isActivitiesOpen);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value
    });
  };

  const getPlanAmount = () => {
    switch (formData.plan) {
      case 'NGN 20,000 for 1 satisfactory session':
        return 20000; // Amount in Naira
      case 'NGN 55,000 for 3 satisfactory sessions':
        return 55000; // Amount in Naira
      case 'NGN 130,000 for 7 satisfactory sessions':
        return 130000; // Amount in Naira
      default:
        return 0;
    }
  };

  const handleSubmitData = async (reference) => {
    setIsLoading(true);

    try {
      const submissionData = {
        ...formData,
        paymentReference: reference.reference // Include payment reference from Paystack
      };
      
      const response = await fetch('https://serenimindbackend.onrender.com/api/submit-form/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        const token = localStorage.getItem('access_token');
        
        setTimeout(() => {
          window.location.reload(); // This will reload the page
          navigate('/book-professional');
        }, 2000);
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: formData.email,
    amount: getPlanAmount() * 100, // Convert to kobo (smallest currency unit)
    publicKey: 'pk_live_bfb0263a1936ad4baaa557469223a3e2c2b17144', // Replace with your actual Paystack public key
    metadata: {
      custom_fields: [
        {
          display_name: "Phone Number",
          variable_name: "phone_number",
          value: formData.phoneNumber,
        },
        {
          display_name: "Plan",
          variable_name: "plan",
          value: formData.plan,
        }
      ]
    },
    currency: 'NGN'
  };

  const handlePaystackSuccess = (reference) => {
    console.log('Payment successful:', reference);
    handleSubmitData(reference);
  };

  const handlePaystackClose = () => {
    console.log('Payment canceled');
  };

  const componentProps = {
    ...config,
    text: 'Proceed to Payment',
    onSuccess: handlePaystackSuccess,
    onClose: handlePaystackClose,
  };

  const today = new Date().toISOString().split('T')[0];
  
  const validateForm = () => {
    return formData.date && 
           formData.time && 
           formData.reason && 
           formData.phoneNumber && 
           formData.email && 
           formData.language && 
           formData.plan;
  };
  
  return (
    <div className="flex my-4 md:mt-28 mt-16">
      <div className="max-w-lg p-6 mx-auto mb-6 bg-white rounded-md shadow-md slide-right">
        <h2 className="mb-4 text-2xl font-semibold text-center text-gray-800">Book a Mental Health Professional</h2>
        <div className="space-y-4">
          {/* Date and Time */}
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="w-full">
              <label className="block mb-1 text-sm font-medium text-gray-700">Preferred Date</label>
              <input
                type="date"
                name="date"
                required
                min={today}
                value={formData.date}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
              />
            </div>
            <div className="w-full mt-4 md:mt-0">
              <label className="block mb-1 text-sm font-medium text-gray-700">Preferred Time</label>
              <input
                type="time"
                name="time"
                required
                value={formData.time}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
              />
            </div>
          </div>

          {/* Phone Number */}
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Phone Number</label>
            <PhoneInput
              country={'ng'}
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              inputClass="!w-full !py-2 !pl-14 !pr-4 !border !rounded-md !focus:outline-none !focus:ring-2 !focus:ring-[#59844F]"
              required
            />
          </div>

          {/* Email */}
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="you@example.com"
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
            />
          </div>

          {/* Language */}
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Preferred Language</label>
            <input
              type="text"
              name="language"
              value={formData.language}
              onChange={handleInputChange}
              placeholder="e.g., English"
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
            />
          </div>

          {/* Reason for Booking */}
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Reason for Booking</label>
            <textarea
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
              rows="4"
              placeholder="Briefly describe your reason for booking..."
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
            ></textarea>
          </div>

          {/* Plan Selection */}
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Choose a Plan</label>
            <select
              name="plan"
              value={formData.plan}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
            >
              <option value="">Select a plan</option>
              <option value="NGN 20,000 for 1 satisfactory session">NGN 20,000 for 1 satisfactory session</option>
              <option value="NGN 55,000 for 3 satisfactory sessions">NGN 55,000 for 3 satisfactory sessions</option>
              <option value="NGN 130,000 for 7 satisfactory sessions">NGN 130,000 for 7 satisfactory sessions</option>
            </select>
          </div>

          {/* Anonymous Option */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="anonymous"
              checked={formData.anonymous}
              onChange={handleInputChange}
              className="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-500"
            />
            <label className="ml-2 text-sm text-gray-700">I would like to remain anonymous</label>
          </div>

          {/* Submit Button */}
          <div className="text-center">
            {validateForm() && getPlanAmount() > 0 ? (
              <PaystackButton 
                {...componentProps} 
                className="w-full px-4 py-2 font-medium text-white bg-[#59844F] rounded-md hover:bg-[#4a6e40] focus:outline-none focus:ring-2 focus:ring-[#59844F]"
                disabled={isLoading}
              />
            ) : (
              <button
                type="button"
                className="w-full px-4 py-2 font-medium text-white bg-[#59844F] rounded-md hover:bg-[#4a6e40] focus:outline-none focus:ring-2 focus:ring-[#59844F] opacity-50 cursor-not-allowed"
                disabled
              >
                Complete Form to Proceed
              </button>
            )}
          </div>
        </div>

        {/* Success Message */}
        {showSuccessMessage && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-6 bg-white rounded-md shadow-md">
              <p className="mb-4 text-lg font-medium text-gray-800">
                Your booking has been sent. We will get back to you soon.
              </p>
              <button
                onClick={() => setShowSuccessMessage(false)}
                className="px-4 py-2 font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      <a href="https://wa.me/2347078634362?text=Hello%2C%20I%20have%20a%20question%20concerning%20your%20service." className="fixed bottom-6 right-6 bg-[#25D366] text-white p-4 rounded-full shadow-lg hover:bg-[#128C7E] transition-colors duration-300">
        <FontAwesomeIcon icon={faWhatsapp} className="text-3xl" />
      </a>
    </div>
  );
};

export default BookServices;