import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle, faCalendarAlt,faTimes, faChevronRight,faPaperPlane, faUser, faComment, faTag, faPhone, faEnvelope, faHandshake, faQuestion, faUserMd, faLock, faCreditCard, faFileAlt, faUserPlus, faHeadset, faHeart, faMapMarkerAlt, faLaptopMedical, faUserFriends, faBriefcase, faGraduationCap, faBook, faMoneyBillWave, faShieldAlt, faHandHoldingHeart, faClipboardList, faAmbulance, faSmile, faMobileAlt, faIdCard, faLanguage, faChild, faWheelchair, faStethoscope, faPills, faMedkit, faComments, faUniversity, faHands } from '@fortawesome/free-solid-svg-icons';
import { faRobot, faBrain } from '@fortawesome/free-solid-svg-icons';

const ChatbotButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([
    { 
      type: 'bot', 
      text: 'Welcome! How can I assist you today? Feel free to ask a question or select one of the options below.', 
      id: Date.now() 
    }
  ]);
  const [userInput, setUserInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const chatRef = useRef(null);
  const buttonRef = useRef(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  
  // Intent definitions with associated keywords
  const intents = [
    {
      intent: "services",
      keywords: ["services", "offer", "provide", "help", "assistance", "therapy", "counseling", "treatment", "care", "offerings"],
      question: "What services do you offer?",
      icon: faInfoCircle
    },
    {
      intent: "booking",
      keywords: ["book", "appointment", "schedule", "reserve", "session", "meeting", "consultation", "visit", "arrange", "set up"],
      question: "How can I book an appointment?",
      icon: faCalendarAlt
    },
    {
      intent: "hours",
      keywords: ["hours", "open", "operating", "schedule", "availability", "time", "when", "days", "business", "working"],
      question: "What are your operating hours?",
      icon: faComment
    },
    {
      intent: "offers",
      keywords: ["offers", "discount", "deal", "special", "promotion", "sale", "savings", "coupon", "price", "package"],
      question: "Do you have any special offers?",
      icon: faTag
    },
    {
      intent: "emergency",
      keywords: ["emergency", "crisis", "urgent", "immediate", "help", "danger", "critical", "severe", "suicide", "harm"],
      question: "What should I do in an emergency?",
      icon: faAmbulance
    },
    {
      intent: "login",
      keywords: ["login", "account", "sign in", "access", "password", "username", "profile", "portal", "dashboard", "credentials"],
      question: "How do I access my account?",
      icon: faLock
    },
    {
      intent: "contact",
      keywords: ["contact", "reach", "email", "phone", "call", "message", "connect", "speak", "talk", "communication"],
      question: "How can I contact your team?",
      icon: faEnvelope
    },
    {
      intent: "partnership",
      keywords: ["partnership", "collaborate", "partner", "work together", "cooperation", "alliance", "joint", "venture", "associate", "affiliation"],
      question: "How can we partner with SereniMind?",
      icon: faHandshake
    },
    {
      intent: "professionals",
      keywords: ["professionals", "doctors", "therapists", "psychologists", "psychiatrists", "specialists", "clinicians", "counselors", "staff", "team"],
      question: "Tell me about your professionals",
      icon: faUserMd
    },
    {
      intent: "payment",
      keywords: ["payment", "pay", "cost", "fee", "charge", "money", "billing", "price", "expense", "financial"],
      question: "What payment methods do you accept?",
      icon: faCreditCard
    },
    {
      intent: "insurance",
      keywords: ["insurance", "coverage", "plan", "policy", "insurer", "covered", "benefits", "claim", "provider", "copay"],
      question: "Do you accept insurance?",
      icon: faShieldAlt
    },
    {
      intent: "records",
      keywords: ["records", "medical", "history", "documents", "files", "chart", "information", "data", "documentation", "reports"],
      question: "How can I access my medical records?",
      icon: faFileAlt
    },
    {
      intent: "register",
      keywords: ["register", "sign up", "new patient", "join", "enroll", "registration", "onboarding", "new client", "create account", "begin"],
      question: "How do I register as a new patient?",
      icon: faUserPlus
    },
    {
      intent: "support",
      keywords: ["support", "help", "assistance", "technical", "issue", "problem", "error", "trouble", "difficulty", "question"],
      question: "How can I get technical support?",
      icon: faHeadset
    },
    {
      intent: "donate",
      keywords: ["donate", "donation", "contribute", "support", "charity", "give", "philanthropy", "funding", "financial help", "sponsorship"],
      question: "Can I support your work through donations?",
      icon: faHeart
    },
    {
      intent: "location",
      keywords: ["location", "address", "where", "place", "office", "find", "directions", "map", "situated", "located"],
      question: "Where are you located?",
      icon: faMapMarkerAlt
    },
    {
      intent: "telehealth",
      keywords: ["telehealth", "virtual", "online", "remote", "video", "digital", "telemedicine", "distance", "zoom", "web"],
      question: "Do you offer virtual consultations?",
      icon: faLaptopMedical
    },
    {
      intent: "family",
      keywords: ["family", "couple", "marriage", "relationship", "parents", "children", "spouse", "partner", "household", "relatives"],
      question: "Do you provide family therapy?",
      icon: faUserFriends
    },
    {
      intent: "career",
      keywords: ["career", "job", "employment", "hiring", "work", "position", "vacancy", "opening", "opportunity", "recruitment"],
      question: "Are you hiring professionals?",
      icon: faBriefcase
    },
    {
      intent: "education",
      keywords: ["education", "learn", "training", "workshop", "course", "class", "seminar", "resources", "materials", "information"],
      question: "Do you offer educational resources?",
      icon: faGraduationCap
    },
    {
      intent: "resources",
      keywords: ["resources", "materials", "tools", "guides", "information", "articles", "videos", "literature", "content", "help"],
      question: "Where can I find mental health resources?",
      icon: faBook
    },
    {
      intent: "pricing",
      keywords: ["pricing", "cost", "fee", "price", "rates", "charges", "expense", "payment", "amount", "budget"],
      question: "What are your service fees?",
      icon: faMoneyBillWave
    },
    {
      intent: "privacy",
      keywords: ["privacy", "confidential", "security", "protection", "sensitive", "data", "information", "secure", "confidentiality", "personal"],
      question: "How do you protect my privacy?",
      icon: faShieldAlt
    },
    {
      intent: "volunteer",
      keywords: ["volunteer", "volunteering", "help", "assist", "support", "unpaid", "community", "service", "contribute", "participation"],
      question: "Can I volunteer with SereniMind?",
      icon: faHandHoldingHeart
    },
    {
      intent: "assessment",
      keywords: ["assessment", "evaluation", "test", "screening", "diagnosis", "examine", "check", "analyze", "review", "measure"],
      question: "Do you offer mental health assessments?",
      icon: faClipboardList
    },
    {
      intent: "waiting",
      keywords: ["waiting", "wait time", "delay", "queue", "line", "duration", "time", "long", "appointment", "schedule"],
      question: "What is your typical wait time?",
      icon: faComment
    },
    {
      intent: "reviews",
      keywords: ["reviews", "testimonials", "feedback", "ratings", "experiences", "opinions", "comments", "satisfaction", "patients", "clients"],
      question: "Where can I see patient reviews?",
      icon: faSmile
    },
    {
      intent: "app",
      keywords: ["app", "application", "mobile", "phone", "download", "software", "platform", "digital", "tool", "technology"],
      question: "Do you have a mobile app?",
      icon: faMobileAlt
    },
    {
      intent: "languages",
      keywords: ["languages", "speak", "translator", "interpretation", "multilingual", "bilingual", "translation", "communication", "foreign", "dialect"],
      question: "What languages do your professionals speak?",
      icon: faLanguage
    },
    {
      intent: "children",
      keywords: ["children", "kids", "child", "young", "youth", "adolescent", "teen", "teenager", "minor", "pediatric"],
      question: "Do you provide services for children?",
      icon: faChild
    },
    {
      intent: "accessibility",
      keywords: ["accessibility", "accessible", "disability", "handicap", "wheelchair", "mobility", "impairment", "accommodation", "facilities", "special needs"],
      question: "Are your facilities accessible?",
      icon: faWheelchair
    },
    {
      intent: "specialists",
      keywords: ["specialists", "specialized", "expert", "specific", "focus", "specialty", "field", "concentration", "area", "discipline"],
      question: "What types of specialists do you have?",
      icon: faStethoscope
    },
    {
      intent: "medication",
      keywords: ["medication", "medicine", "drugs", "prescribe", "prescription", "pharmaceutical", "treatment", "pills", "dose", "therapy"],
      question: "Do you provide medication management?",
      icon: faPills
    },
    {
      intent: "firstvisit",
      keywords: ["first visit", "initial", "new patient", "first time", "begin", "start", "introduction", "orientation", "intake", "onboarding"],
      question: "What should I expect on my first visit?",
      icon: faMedkit
    },
    {
      intent: "feedback",
      keywords: ["feedback", "opinion", "comment", "suggestion", "input", "review", "experience", "thoughts", "critique", "response"],
      question: "How can I provide feedback?",
      icon: faComments
    },
    {
      intent: "training",
      keywords: ["training", "education", "professional", "development", "course", "program", "workshop", "certification", "learning", "skill"],
      question: "Do you offer professional training?",
      icon: faUniversity
    },
    {
      intent: "community",
      keywords: ["community", "program", "outreach", "public", "service", "initiative", "project", "volunteer", "social", "group"],
      question: "Do you have community programs?",
      icon: faHands
    }
  ];
  
  // Common questions for quick selection
  const commonQuestions = intents.map(intent => ({
    text: intent.intent.charAt(0).toUpperCase() + intent.intent.slice(1),
    icon: intent.icon,
    fullText: intent.question
  }));
  
  // Expanded predefined answers
  const getAnswer = (question) => {
    switch(question) {
      case "What services do you offer?":
        return "We offer comprehensive mental healthcare services including counseling, therapy sessions, psychological assessments, crisis intervention, group therapy, and specialized treatments tailored to your unique needs.";
      
      case "How can I book an appointment?":
        return "You can easily book an appointment through our website at www.serenimind.com.ng/book-professional. There you can select your preferred professional and available time slots that work for you.";
      
      case "What are your operating hours?":
        return "We are available 24/7 for our online services. Our physical offices have flexible schedules based on professional availability. For emergencies, we have on-call professionals at all times.";
      
      case "Do you have any special offers?":
        return "Yes! We currently have a 15% discount on comprehensive mental health assessments for new clients. We also offer package deals for multiple sessions and special rates for students and seniors. Contact our customer service for more details.";
      
      case "What should I do in an emergency?":
        return "If you're experiencing a mental health emergency, please call our 24/7 crisis hotline immediately. For severe emergencies that might involve harm to yourself or others, please call emergency services (911).";
      
      case "How do I access my account?":
        return "You can log in to your account at www.serenimind.com.ng/login to access your personal dashboard, appointment history, resources, and communication with your healthcare provider.";
      
      case "How can I contact your team?":
        return "You can reach our team via email at team@serenimind.com.ng, through our contact form on the website, or by calling our customer service line. We typically respond to emails within 24 hours on business days.";
      
      case "How can we partner with SereniMind?":
        return "We welcome partnerships and sponsorships! Please reach out to us at team@serenimind.com.ng with the subject line 'Partnership Opportunity' and provide details about your organization and how you envision collaborating with us.";
      
      case "Tell me about your professionals":
        return "Our team consists of licensed psychologists, therapists, counselors, and psychiatrists, all with extensive experience in various mental health specialties. All professionals undergo rigorous vetting and continual education to provide the highest quality care.";
      
      case "What payment methods do you accept?":
        return "We accept various payment methods including credit/debit cards, bank transfers, and mobile payment solutions. We also have payment plans available for longer treatment programs.";
      
      case "Do you accept insurance?":
        return "Yes, we work with several insurance providers. Please contact our administrative team with your insurance details, and we'll verify your coverage before your appointment.";
      
      case "How can I access my medical records?":
        return "You can access your medical records through your secure account on our website. If you need official copies or have special requests, please contact our records department through your portal or via email.";
      
      case "How do I register as a new patient?":
        return "New patients can register on our website by clicking the 'New Patient' button and filling out the required information. The process takes about 10 minutes, and you'll need to provide basic personal information and health history.";
      
      case "How can I get technical support?":
        return "For technical issues with our website or app, please email support@serenimind.com.ng or use the chat feature on our website. Include screenshots if possible to help us resolve your issue more quickly.";
      
      case "Can I support your work through donations?":
        return "Yes, we gratefully accept donations to support our community outreach programs and subsidized care for those who cannot afford mental health services. Visit our 'Support Us' page for more information.";
      
      case "Where are you located?":
        return "We have multiple locations across major cities. Our headquarters is located in Lagos, with satellite offices in Abuja, Port Harcourt, and Ibadan. Exact addresses and directions can be found on our 'Locations' page.";
      
      case "Do you offer virtual consultations?":
        return "Yes, we offer secure video consultations through our platform. These are conducted with the same quality standards as in-person visits and are available for most of our services.";
      
      case "Do you provide family therapy?":
        return "Yes, we offer comprehensive family therapy services designed to improve communication and resolve conflicts within families. Our therapists are specialized in various family dynamics and age groups.";
      
      case "Are you hiring professionals?":
        return "We're always looking for qualified mental health professionals to join our team. Please visit our 'Careers' page for current openings and application procedures.";
      
      case "Do you offer educational resources?":
        return "Yes, we have an extensive library of educational resources on mental health topics, including articles, videos, and self-help tools, all accessible through our website and app.";
      
      case "Where can I find mental health resources?":
        return "Our website has a dedicated 'Resources' section with articles, videos, worksheets, and recommended readings. Registered users also get access to premium resources tailored to their specific needs.";
      
      case "What are your service fees?":
        return "Our service fees vary depending on the type of service and the professional's qualification level. We strive to keep our services affordable and offer sliding scale fees based on financial need. Detailed pricing information is available on our website.";
      
      case "How do you protect my privacy?":
        return "We take privacy extremely seriously. All communications are encrypted, and we comply with all healthcare privacy regulations. Your information is never shared without your explicit consent except as required by law.";
      
      case "Can I volunteer with SereniMind?":
        return "Yes, we have volunteer opportunities for both clinical and non-clinical roles. Please visit our 'Volunteer' page to learn about current needs and application procedures.";
      
      case "Do you offer mental health assessments?":
        return "Yes, we provide comprehensive mental health assessments conducted by qualified professionals. These can help identify concerns and create personalized treatment plans.";
      
      case "What is your typical wait time?":
        return "Our typical wait time for new appointments is 1-2 weeks, though urgent cases are prioritized. For established patients, follow-up appointments are usually available within a few days.";
      
      case "Where can I see patient reviews?":
        return "Patient reviews and testimonials can be found on our website's 'Testimonials' page. We also have profiles on various healthcare review platforms where patients share their experiences.";
      
      case "Do you have a mobile app?":
        return "Yes, our mobile app is available for both iOS and Android devices. It provides secure access to your account, appointment scheduling, telehealth sessions, and resources.";
      
      case "Which insurance plans do you accept?":
        return "We accept most major insurance plans. Please check our 'Insurance' page for a current list or contact our billing department to verify your specific coverage.";
      
      case "What languages do your professionals speak?":
        return "Our team includes professionals who speak various languages including English, Yoruba, Hausa, Igbo, French, and more. You can filter professionals by language preference when booking appointments.";
      
      case "Do you provide services for children?":
        return "Yes, we have child psychologists and therapists who specialize in working with children of all ages. We use age-appropriate approaches and create comfortable environments for young patients.";
      
      case "Are your facilities accessible?":
        return "Yes, all our physical locations are fully accessible for individuals with mobility challenges. We also offer accommodations for various other accessibility needs upon request.";
      
      case "What types of specialists do you have?":
        return "Our team includes specialists in anxiety, depression, trauma, addiction, eating disorders, relationship issues, LGBTQ+ concerns, geriatric mental health, and many other areas.";
      
      case "Do you provide medication management?":
        return "Yes, our psychiatrists provide medication evaluations and ongoing management. They work closely with our therapy team to ensure comprehensive care.";
      
      case "What should I expect on my first visit?":
        return "Your first visit will typically last 50-60 minutes and include a comprehensive assessment of your concerns, history, and goals. Together with your provider, you'll develop an initial treatment plan.";
      
      case "How can I provide feedback?":
        return "We value your feedback! You can provide feedback through our website, after appointments via our automated follow-up emails, or directly to our quality assurance team at feedback@serenimind.com.ng.";
      
      case "Do you offer professional training?":
        return "Yes, we offer continuing education programs, workshops, and internships for mental health professionals and students. Details can be found on our 'Professional Development' page.";
      
      case "Do you have community programs?":
        return "We run various community programs including free mental health screenings, educational workshops, support groups, and outreach initiatives in underserved areas.";
      
      default:
        return "I'm not sure I understand your question completely. Could you rephrase or select one of the suggested topics? Alternatively, you can connect with our customer service team directly on WhatsApp or email team@serenimind.com.ng for more specific assistance.";
    }
  };
  
  // Function to analyze user input and find the best matching intent
  const findIntent = (userInput) => {
    if (!userInput || userInput.trim() === '') return null;
    
    // Preprocess the user input
    const words = userInput.toLowerCase().split(/\s+/);
    const cleanedWords = words.map(word => word.replace(/[.,?!;:()]/g, ''));
    
    // Calculate score for each intent based on keyword matches
    let bestMatch = null;
    let highestScore = 0;
    
    intents.forEach(intent => {
      let score = 0;
      let matches = 0;
      
      // Check each word in user input against intent keywords
      cleanedWords.forEach(word => {
        if (word.length < 3) return; // Skip very short words
        
        intent.keywords.forEach(keyword => {
          // Check for exact match
          if (keyword.toLowerCase() === word) {
            score += 3;
            matches++;
          }
          // Check for partial match (word is part of keyword or keyword is part of word)
          else if (keyword.toLowerCase().includes(word) || word.includes(keyword.toLowerCase())) {
            score += 1;
            matches++;
          }
        });
        
        // Check if intent name is mentioned directly
        if (intent.intent.toLowerCase() === word) {
          score += 5;
          matches++;
        }
      });
      
      // Check for intent keywords as phrases in the full input
      const inputLower = userInput.toLowerCase();
      intent.keywords.forEach(keyword => {
        if (keyword.includes(' ') && inputLower.includes(keyword.toLowerCase())) {
          score += 4;
          matches++;
        }
      });
      
      // Prioritize queries with more matches and higher total score
      if (matches > 0 && (
        score > highestScore || 
        (score === highestScore && matches > (bestMatch ? bestMatch.matches : 0))
      )) {
        highestScore = score;
        bestMatch = {
          intent: intent,
          score: score,
          matches: matches
        };
      }
    });
    
    // Return the best match if score is above threshold
    return bestMatch && bestMatch.score >= 2 ? bestMatch.intent : null;
  };
  
  const toggleButtonExpansion = () => {
    setIsExpanded(!isExpanded);
    // Close chat if collapsing the button
    if (isExpanded) {
      setIsChatOpen(false);
    }
  };
  
  const openChat = () => {
    setIsChatOpen(true);
    setIsExpanded(false); // Collapse buttons after selecting an option
    
    // Focus on input field when chat opens
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };
  
  const handleSendMessage = () => {
    if (userInput.trim() === '') return;
    
    // Add user message
    const userQuestion = userInput;
    const newUserMessage = { type: 'user', text: userQuestion, id: Date.now() };
    setMessages(prev => [...prev, newUserMessage]);
    setUserInput('');
    
    // Show typing indicator
    setIsTyping(true);
    
    // Process user input to find matching intent
    const matchedIntent = findIntent(userQuestion);
    let response;
    
    if (matchedIntent) {
      response = getAnswer(matchedIntent.question);
    } else {
      // No strong match found
      response = "I'm not sure I understand your question. Could you rephrase or select one of the suggested topics? Alternatively, you can connect with our customer service team directly on WhatsApp for more specific assistance.";
    }
    
    // Simulate bot thinking with a natural delay
    setTimeout(() => {
      setIsTyping(false);
      const botResponse = { type: 'bot', text: response, id: Date.now() + 1 };
      setMessages(prev => [...prev, botResponse]);
    }, 1500);
  };
  
  const handleQuickQuestion = (question) => {
    // Add user question
    const userQuestion = { type: 'user', text: question, id: Date.now() };
    setMessages(prev => [...prev, userQuestion]);
    
    // Show typing indicator
    setIsTyping(true);
    
    // Simulate bot thinking
    setTimeout(() => {
      setIsTyping(false);
      const botResponse = { type: 'bot', text: getAnswer(question), id: Date.now() + 1 };
      setMessages(prev => [...prev, botResponse]);
    }, 1500);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };
  
  // Close buttons and chat when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsExpanded(false);
        
        if (chatRef.current && !chatRef.current.contains(event.target)) {
          setIsChatOpen(false);
        }
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatRef, buttonRef]);
  
  // Auto-scroll to bottom of messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isTyping]);

  // Format timestamp
  const formatTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="fixed bottom-6 right-6 flex flex-col items-end z-50">
      {/* Chatbot Panel with animation */}
      {isChatOpen && (
        <div 
          ref={chatRef}
          className="bg-white rounded-lg shadow-xl mb-4 flex flex-col overflow-hidden transition-all duration-500 animate-fade-in"
          style={{ width: '350px', height: '500px' }}
        >
          {/* Chat Header */}
          <div className="bg-[#31452c] p-4 flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <div className="rounded-full bg-white p-2 flex items-center justify-center">
                <FontAwesomeIcon 
                  icon={faRobot} 
                  className="text-[#31452c] text-lg" 
                />
              </div>
              <div className="text-white">
                <h3 className="font-medium text-lg">Virtual Assistant</h3>
                <p className="text-xs opacity-80">Online | {formatTime()}</p>
              </div>
            </div>
            <button 
              onClick={() => setIsChatOpen(false)}
              className="text-white hover:text-[#ffa07a] p-1 rounded-full hover:bg-[#3a5236] transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          
          {/* Chat Messages */}
          <div className="flex-1 p-4 overflow-y-auto" style={{ maxHeight: '300px' }}>
            {/* Welcome message with logo */}
            <div className="flex justify-center mb-4">
              <div className="rounded-full bg-[#31452c] p-3 flex items-center justify-center">
                <FontAwesomeIcon 
                  icon={faRobot} 
                  className="text-white text-xl" 
                />
              </div>
              <h4 className="sr-only">Welcome to our virtual assistant</h4>
            </div>
          
            {messages.map((msg) => (
              <div 
                key={msg.id} 
                className={`mb-4 flex ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                {msg.type === 'bot' && (
                  <div className="mr-2 flex-shrink-0">
                    <FontAwesomeIcon 
                      icon={faRobot} 
                      className="text-[#31452c]" 
                    />
                  </div>
                )}
                <div 
                  className={`max-w-[75%] rounded-lg px-4 py-2 ${
                    msg.type === 'user' 
                      ? 'bg-[#31452c] text-white rounded-tr-none' 
                      : 'bg-gray-100 text-gray-800 rounded-tl-none'
                  }`}
                >
                  {msg.text}
                  <div className="text-xs mt-1 opacity-70">
                    {formatTime()}
                  </div>
                </div>
                {msg.type === 'user' && (
                  <div className="ml-2 flex-shrink-0">
                    <FontAwesomeIcon 
                      icon={faUser} 
                      className="text-[#31452c]" 
                    />
                  </div>
                )}
              </div>
            ))}
            
            {/* Typing indicator */}
            {isTyping && (
              <div className="flex mb-4 justify-start">
                <div className="mr-2 flex-shrink-0">
                  <FontAwesomeIcon 
                    icon={faRobot} 
                    className="text-[#31452c]" 
                  />
                </div>
                <div className="bg-gray-100 text-gray-800 rounded-lg rounded-tl-none p-3 max-w-[75%]">
                  <div className="flex space-x-1">
                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                  </div>
                </div>
              </div>
            )}
            
            <div ref={messagesEndRef} />
          </div>
          
          {/* Quick Questions */}
          <div className="px-4 py-2 flex gap-2 overflow-x-auto" style={{ maxWidth: '350px' }}>
            {commonQuestions.map((q, index) => (
              <button 
                key={index} 
                onClick={() => handleQuickQuestion(q.fullText)}
                className="px-3 py-2 bg-white text-sm rounded-lg hover:bg-gray-50 transition-colors duration-300 shadow-sm text-[#31452c] border border-gray-200 flex items-center"
              >
                <FontAwesomeIcon icon={q.icon} className="mr-2" />
                {q.text}
              </button>
            ))}
          </div>
          
          {/* Message Input */}
          <div className="p-3 border-t flex items-center">
            <input 
              ref={inputRef}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your question..."
              className="flex-grow px-4 py-3 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-[#31452c] focus:border-transparent transition-all duration-300"
            />
            <button 
              onClick={handleSendMessage}
              className="bg-[#31452c] text-white px-4 py-3 rounded-r-lg hover:bg-[#3a5236] transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
          
          {/* Footer with WhatsApp option */}
          <div className="p-3 border-t bg-gray-50">
            <a 
              href="https://wa.me/2347078634362"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center text-[#31452c] text-sm hover:text-[#3a5236] transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faWhatsapp} className="mr-2 text-green-600" />
              Connect with a real person on WhatsApp
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </a>
          </div>
        </div>
      )}
      
      {/* Buttons container */}
      <div 
        ref={buttonRef} 
        className="relative"
      >
        {isExpanded ? (
          <div className="flex space-y-reverse space-x-5 mb-3 items-center animate-fade-in">
            {/* AI Button with animation */}
            <button
              onClick={openChat}
              className="bg-[#31452c] text-white p-4 rounded-full shadow-lg hover:bg-[#3a5236] transition-colors duration-300 animate-bounce-in"
              style={{ animationDelay: '0.1s' }}
            >
              <FontAwesomeIcon icon={faBrain} size="lg" />
            </button>
            
            {/* WhatsApp Button with animation */}
            <a
              href="https://wa.me/2347078634362"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300 animate-bounce-in"
              style={{ animationDelay: '0.2s' }}
            >
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            </a>
          </div>
        ) : (
          <button
            onClick={toggleButtonExpansion}
            className="bg-[#31452c] text-white p-4 rounded-full shadow-lg hover:bg-[#3a5236] transition-colors duration-300"
          >
            <FontAwesomeIcon icon={faComment} size="lg" />
          </button>
        )}
      </div>

      {/* CSS Animations */}
      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        @keyframes bounceIn {
          0% { opacity: 0; transform: scale(0.3); }
          50% { opacity: 1; transform: scale(1.05); }
          70% { transform: scale(0.9); }
          100% { transform: scale(1); }
        }
        .animate-fade-in {
          animation: fadeIn 0.3s ease-out forwards;
        }
        .animate-bounce-in {
          animation: bounceIn 0.5s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default ChatbotButton;