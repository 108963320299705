import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Features from './components/Features';
import Signup from './components/Signup';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Verification from './components/Verification';
import Donate from './components/Donate';
import ResetPassword from './components/ResetPassword';
import ForgotPasswordVerification from './components/ForgotPasswordVerification';
import CommunityLanding from './components/CommunityLanding';
import Pricing from './components/Pricing';
import Dashboardmain from './accessible-components/Dashboardmain';
import NavbarLogged from './accessible-components/NavbarLogged';
import PasswordResetForm from './components/PasswordResetForm';
import PasswordResetComplete from './components/PasswordResetComplete';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import BookServices from './accessible-components/BookServices';
import SereniAi from './accessible-components/SereniAi';
import Book from './accessible-components/Book';
import Movies from './accessible-components/Movies';
import Games from './accessible-components/Games';
import Journal from './accessible-components/Journal';
import CreateBlog from './accessible-components/CreateBlog';
import Settings from './accessible-components/Settings';
import Exercises from './accessible-components/Exercises';
import Sounds from './accessible-components/Sounds';
import Community from './accessible-components/Community';
import BlogData from './blogpost/BlogData';
import BlogPost from './blogpost/BlogPost';
import Blog from './accessible-components/Blog';
import CommunityPost from './community/CommunityPost';
import CommunityDataPage from './community/CommunityData';
import FooterLogged from './accessible-components/FooterLogged';
import PrivateRoutes from './accessible-components/PrivateRoutes';
import NoPageFound from './components/NoPageFound';
import ResetPasswordSuccess from './components/ResetPasswordSuccess ';
import ResetPasswordForm from './components/ResetPasswordForm ';
import CreateCommunities from './community/CreateCommunities';
import VerifyZoho from './components/VerifyZoho';
import { AuthContext } from "./components/AuthContext ";
import ActivityLounge from './accessible-components/ActivityLounge';
import BookProffesional from './components/BookProffesional';
import Blogs from './components/Blogs';
import MoodAssessment from './accessible-components/MoodAssessment';
import TermsAndConditions from './components/TermsAndConditions';
import Status from './components/Status';
import InteractiveTour from './accessible-components/InteractiveTour';
import AiChatbot from './components/Aichatbot';
import MonnifyPayment from './components/MonnifyPayment';

function App() {
  const { isAuthenticated, loading } = useContext(AuthContext);

  useEffect(() => {
    // Optional: any side effect related to auth state change
  }, [isAuthenticated]);

  if (loading) {
    return <div>Loading...</div>; // Or a spinner
  }

  return (
    <div className="App">
        <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Poppins:wght@400;500;600&display=swap" rel="stylesheet" />
      <link href="https://api.fontshare.com/v2/css?f[]=lostamasta@400&display=swap" rel="stylesheet" />

   
      {isAuthenticated() ? <NavbarLogged /> : <NavBar />}
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboardmain" element={<Dashboardmain />} />
          <Route path="/books" element={<Book />} />
          <Route path="/movies" element={<Movies />} />
          <Route path="/games" element={<Games />} />
          <Route path="/journal" element={<Journal />} />
          <Route path="/createblog" element={<CreateBlog />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/exercises" element={<Exercises />} />
          <Route path="/sounds" element={<Sounds />} />
          <Route path="/community" element={<Community />} />
          <Route path="/community-post/:communityId" element={<CommunityPost community={CommunityDataPage} />} />
          <Route path="/sereniai" element={<SereniAi />} />
          <Route path='/activity-lounge' element={<ActivityLounge/>}/>
          <Route path="/book-professionals" element={<BookServices />} />
          <Route path="/blog" element={<Blog blogs={BlogData} />} />
          <Route path='/mood-assessment' element={<MoodAssessment/>}/>

        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/features" element={<Features />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/forgotpasswordverification" element={<ForgotPasswordVerification />} />
        <Route path="/communitylanding" element={<CommunityLanding />} />
        <Route path="/password-reset-form" element={<PasswordResetForm />} />
        <Route path="/password-reset-complete" element={<PasswordResetComplete />} />
        <Route path="/forgot-password-form" element={<ForgotPasswordForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/nopagefound" element={<NoPageFound />} /> {/* Login route */}
        <Route path="*" element={<Navigate to="/nopagefound" />} />
        <Route path="/resetpasswordsuccess" element={<ResetPasswordSuccess />} />
        <Route path="/resetpasswordform/:ref" element={<ResetPasswordForm />} />
        <Route path="/createcommunity" element={<CreateCommunities />} />
        <Route path="/blogs" element={<Blogs blogs={BlogData} />} />
        <Route path="/blog/:id" element={<BlogPost blogData={BlogData} />} />
        <Route path='/book-professional' element={<BookProffesional/>}/>
        <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
        <Route path='/status' element={<Status/>}/>
        <Route path='/interactivetour' element={<InteractiveTour/>}/>
        <Route path='/aichatbot' element={<AiChatbot/>} />
        <Route path='/monnify' element={<MonnifyPayment/>}/>

      </Routes>
      {isAuthenticated() ? <FooterLogged /> : <Footer />}
    </div>
  );
}

export default App;
