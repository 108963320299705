// PaymentButton.jsx
import React from 'react';

const PaymentButton = () => {
  const payWithMonnify = () => {
    window.MonnifySDK.initialize({
      amount: 1000, // Amount to be paid
      currency: 'NGN', // Currency
      reference: new Date().getTime().toString(), // Unique reference
      customerFullName: 'John Doe', // Customer's full name
      customerEmail: 'johndoe@example.com', // Customer's email
      apiKey: 'MK_TEST_WRRPQH8TRX', // Replace with your Monnify API key
      contractCode: '6759572933', // Replace with your Monnify contract code
      paymentDescription: 'Payment for services',
      metadata: {
        customData: 'Additional info', // Additional metadata if needed
      },
      onLoadStart: () => {
        console.log('Monnify SDK loading started');
      },
      onLoadComplete: () => {
        console.log('Monnify SDK is ready');
      },
      onComplete: (response) => {
        console.log('Transaction complete:', response);
        // Handle post-transaction logic here
      },
      onClose: (data) => {
        console.log('Payment modal closed:', data);
        // Handle modal close event here
      },
    });
  };

  return (
    <button
      onClick={payWithMonnify}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center mx-auto my-56" 
    >
      Pay with Monnify
    </button>
  );
};

export default PaymentButton;
