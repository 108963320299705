import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faInstagram, faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SereniMindlogoMain from '../images/SereniMindLogo1 (2).jpg';
import TermsAndConditions from './TermsAndConditions'; // Make sure to import the TermsAndConditions component

const Footer = () => {
  const form = useRef(null);
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const formNewsletter = useRef();
  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const formMessage = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const subscribeNewsletter = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    setIsSubscribing(true);
    const formData = new FormData(formNewsletter.current);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/subscribe-newsletter/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        window.location.reload();
      } else {
        alert('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to subscribe. Please try again later.');
    } finally {
      setIsSubscribing(false);
      setIsLoading(false);
    }
  };



  const FooterSection = ({ title, children }) => (
    <div>
      <h2 className="font-bold text-lg mb-4 text-gray-200">{title}</h2>
      {children}
    </div>
  );

  const FooterLink = ({ to, children, onClick }) => (
    <button 
      onClick={onClick} 
      className="block mb-2 text-left text-gray-300 hover:text-white transition-colors duration-300"
    >
       <Link to={to} className="block mb-2 text-gray-300 hover:text-white transition-colors duration-300">
    {children}
  </Link>
    </button>
    
  );

  const SocialIcon = ({ href, icon }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" 
       className="mr-4 text-2xl text-gray-300 hover:text-white transition-colors duration-300">
      <FontAwesomeIcon icon={icon} />
    </a>
  );

  return (
    <footer className='bg-[#2c3a2c] text-gray-300 px-6 py-12 md:px-12 lg:px-24'>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
        <div>
          <img src={SereniMindlogoMain} alt="SereniMind Logo" className='w-32 mb-6' />
          <p className='mb-6 max-w-xs text-sm leading-relaxed'>
            We're here to offer guidance, support, and a helping hand through your journey to better mental health and well-being.
          </p>
          <div className="hidden md:flex space-x-4">
            <SocialIcon href="https://www.linkedin.com/company/serenimind" icon={faLinkedinIn} />
            <SocialIcon href="https://www.instagram.com/serenimindng" icon={faInstagram} />
            <SocialIcon href="https://www.x.com/serenimind" icon={faXTwitter} />
            <SocialIcon href="https://www.facebook.com/serenimind" icon={faFacebook} />
          </div>
        </div>

        <FooterSection title="Company">
          <FooterLink to='/'>Home</FooterLink>
          <FooterLink to='/blogs'>Blog</FooterLink>
          <FooterLink to='/features'>Products</FooterLink>
          <FooterLink to='/contact'>Contact</FooterLink>
        </FooterSection>

        <FooterSection title="Support">
          <FooterLink to='/contact'>Help Center</FooterLink>
          <FooterLink onClick={() => setShowTerms(true)}>Terms of Service</FooterLink>
          <FooterLink onClick={() => setShowTerms(true)}>Legal</FooterLink>
          <FooterLink onClick={() => setShowTerms(true)}>Privacy Policy</FooterLink>
          <FooterLink to='/status'>Status</FooterLink>
        </FooterSection>

        <FooterSection title="Stay Connected">
        <form ref={formNewsletter} onSubmit={subscribeNewsletter} className="space-y-3">
        <div className="relative">
              <FontAwesomeIcon icon={faEnvelope} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input 
                type="email" 
                name="user_email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Your email address' 
                className="w-full pl-10 pr-3 py-2 bg-[#3c493c] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-[#5b7444]" 
                required 
                />
            </div>
            <button 
              type="submit" 
              className="w-full bg-[#5b7444] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#4a5e36] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5b7444]"
            >
            {isLoading ? 'Subscribing...' : 'Subscribe'}
            </button>
          </form>
          {subscriptionStatus && (
            <p className="mt-2 text-sm text-center">{subscriptionStatus}</p>
          )}
        </FooterSection>
      </div>

      <div className="mt-12 pt-8 border-t border-gray-700">
        <div className="flex md:hidden justify-center space-x-6 mb-6">
          <SocialIcon href="https://www.linkedin.com/company/serenimind" icon={faLinkedinIn} />
          <SocialIcon href="https://www.instagram.com/serenimindng" icon={faInstagram} />
          <SocialIcon href="https://www.x.com/serenimind" icon={faXTwitter} />
          <SocialIcon href="https://www.facebook.com/serenimind" icon={faFacebook} />
        </div>
        <p className='text-center text-sm'>
          &copy; {new Date().getFullYear()} SereniMind. All rights reserved.
        </p>
      </div>

      {showTerms && <TermsAndConditions onClose={() => setShowTerms(false)} />}
    </footer>
  );
};

export default Footer;